<template>
  <CLCard
    width="620px"
    height="659px"
    heading="Enter your invite code"
    type="primary"
    action-text="Create your account"
    action-event="create-account"
    :loading="loading"
    @create-account="$emit('create-account', inviteCode, emailAddress)"
  >
    <template #default>
      <p>Your client administrator will have sent this to you. It is a 6-digit number, for example <b>462985</b></p>
      <CLTextbox
        v-model="inviteCode"
        qe-id="received-invite-code"
        :error-messages="codeMessages"
        label="Your invite code"
        :rules="rules.inviteCode"
      />
      <CLTextbox
        v-model="emailAddress"
        qe-id="received-invite-email"
        label="Your email address"
        :rules="rules.emailAddress"
        :disabled="!!initialEmail"
        :readonly="!!initialEmail"
      />
    </template>
    <template #links>
      <CLTextLink qe-id="received-invite-return" @click="$emit('return')">
        Return to log in page
      </CLTextLink>
    </template>
  </CLCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLCard,
  CLTextbox,
  CLTextLink,
  required,
  validatorSetup
} from "@clearlife-limited/ui-library";

export default defineComponent({
  name: "CLReceivedInvite",
  components: {
    CLCard,
    CLTextbox,
    CLTextLink,
  },
  props: {
    codeIsInvalidMessage: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    initialEmail: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: {
    return: () => true,
    ["create-account"]: (() => true) as (inviteCode: string, emailAddress: string) => boolean,
  },
  data() {
    return {
      inviteCode: "",
      emailAddress: this.initialEmail,
    };
  },
  computed: {
    codeMessages() {
      return this.codeIsInvalidMessage ? [this.codeIsInvalidMessage] : [];
    },
    rules() {
      return validatorSetup({
        inviteCode: { required: required("Invite") },
        emailAddress: { required: required("Email") },
      });
    },
  },
  watch: {
    initialEmail(newValue: string){
      this.emailAddress = newValue;
    },
  },
});
</script>

<style scoped lang="scss">
::v-deep .cl-textbox {
    margin-top: 15px;
    margin-bottom: 10px;
}
</style>
