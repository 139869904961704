import Vue from "vue";
import type { ICellRendererParams } from "@ag-grid-community/core";
import type { UserDto } from "@clarinet/api/auth";
import { CLTag } from "@clearlife-limited/ui-library";
import type { TagItem } from "../../Utility/UserTags";
import { extractUserTags, tagNameToTagItem } from "../../Utility/UserTags";

export default Vue.extend({
  components: {
    ClTag: CLTag,
  },
  data() {
    return {
      user: <UserDto | undefined>undefined,
    };
  },
  computed: {
    tags(): TagItem[] {
      return extractUserTags(this.user).map(tagNameToTagItem);
    },
  },
  beforeMount() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const strongParams = <ICellRendererParams>(<any>this).params;
    this.user = strongParams.data;
  },
});
