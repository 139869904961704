import { defineComponent } from "vue";
import Roles from "../Components/Roles.vue";
import Password from "../Components/Password.vue";
import type { Validator } from "@clearlife-limited/ui-library";
import {
  email,
  validatorSetup,
  required,
  CLAlert,
  CLTextbox,
  CLButtonBar,
  CLKeyline,
  CLCheckbox,
  CLSelect
} from "@clearlife-limited/ui-library";
import type { OrganisationDto, UserCreateDto } from "@clarinet/api/auth";
import type { RoleChanges } from "@clarinet/src/Components/Roles";
import { OrganisationsApi, RolesApi, UsersApi } from "@clarinet/src/api";

export default defineComponent({
  components: {
    Roles,
    Password,
    CLTextbox,
    CLButtonBar,
    CLKeyline,
    CLCheckbox,
    CLSelect,
    CLAlert
  },
  data() {
    return {
      emailAddress: "",
      emailAddressDirty: false,
      login: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      subscriber: <any>null,
      organisations: <OrganisationDto[]>[],
      firstName: "",
      lastName: "",
      valid: false,
      hasErrors: false,
      errors: [] as string[],
      applyClearlifeMfa: false,
      userRoles: <RoleChanges>{
        addRoles: [],
        removeRoles: [],
      },
      contractualUser: true,
    };
  },
  computed: {
    rules() {
      return validatorSetup({
        emailAddress: {
          required: required("Email address"),
          mustLookLikeAnEmail: email("Email address"),
        },
        firstName: { required: required("First name") },
        lastName: { required: required("Last name") },
        login: { required: required("Login") },
        subscriber: { required: required("Subscriber") },
      });
    },
    form(): Validator {
      return this.$refs.form as unknown as Validator;
    },
  },
  async mounted() {
    const orgResponse = await OrganisationsApi.all();
    this.organisations = orgResponse.data;
  },
  methods: {
    cancelEdit() {
      this.$goBack(1);
    },
    onLoginEdit() {
      // If the user has already started entering an email address, don't override them
      if (this.emailAddressDirty) return;

      const userNamePrefix = "clearlife_";

      if (this.login.startsWith(userNamePrefix) && this.login.length > userNamePrefix.length) {
        this.emailAddress = `infrastructure+${this.login}@clearlifeltd.com`;
        this.contractualUser = false;
        this.applyClearlifeMfa = true;
      } else {
        this.emailAddress = "";
        this.contractualUser = true;
        this.applyClearlifeMfa = false;
      }
    },
    async saveEdit() {
      this.errors = [];
      this.hasErrors = false;
      this.form.validate();
      if (!this.valid) return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const passwordData = (<any>this.$refs.passwordDetails).getData();
      const payload: UserCreateDto = {
        email: this.emailAddress,
        firstName: this.firstName,
        lastName: this.lastName,
        organisationName: this.subscriber,
        userName: this.login,
        password: passwordData.newPassword,
        confirmPassword: passwordData.newPasswordConfirmed,
        contractualUser: this.contractualUser,
        applyClearlifeMfa: !this.contractualUser && this.applyClearlifeMfa,
      };
      try {
        await UsersApi.createUser(payload);
      } catch (err) {
        this.hasErrors = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = (<any>err).response;
        if (response?.status === 400) {
          this.errors.push(response.data.toString());
        } else {
          // This is a CL system admin page only. Accept sub-optimal error handling for now.
          this.errors.push("Sorry, something went wrong. Please try again later.");
        }
        return;
      }
      await RolesApi.changeUserRoles(this.login, this.userRoles);
      this.$goBack(1);
    },
  },
});
