<template>
  <div>
    <h2>Email Preferences</h2>
    <p>You can manage your ClearLife email subscriptions here. Tick the options that you would like to receive.</p>
    <CLAlert v-if="error" type="error" qe-id="email-preference-error">
      {{ error }}
    </CLAlert>
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar"
      :width="360"
      height="70"
      class="preference-loader"
    />
    <template v-else>
      <CLCheckbox
        v-for="preference in preferences"
        :key="preference.label"
        v-model="preference.enabled"
        :label="preference.label"
        :qe-id="createQeId('email-preference', preference.label)"
        :messages="preference.description"
        @change="save"
      />
    </template>
  </div>
</template>

<script lang="ts">
import type { UserEmailPreferencesDto } from "@clarinet/api/auth";
import { defineComponent } from "vue";
import { CLAlert, CLCheckbox, createQeId } from "@clearlife-limited/ui-library";
import { UsersApi } from "@clarinet/src/api";

export default defineComponent({
  components: {
    CLAlert,
    CLCheckbox
  },
  data() {
    return {
      error: "",
      loading: false,
      preferences: {} as UserEmailPreferencesDto,
    };
  },
  async mounted() {
    this.loading = true;

    try {
      const response = await UsersApi.getEmailPreferences();
      this.preferences = response.data;
    } catch {
      this.error = "Something went wrong obtaining your current preferences, please try again."
    } finally {
      this.loading = false;
    }
  },
  methods: {
    createQeId,
    async save() {
      this.loading = true;
      this.error = "";

      try {
        await UsersApi.setEmailPreferences(this.preferences);
      } catch {
        this.error = "Something went wrong saving your preferences, please try again.";
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped>
.cl-checkbox {
  width: auto !important;
}
::v-deep .v-messages {
  padding-left: 40px;
}
::v-deep .v-messages,
::v-deep .v-messages.primary--text {
  color: black !important;
}

::v-deep .preference-loader {
  margin-top: -10px;
}
::v-deep .preference-loader .v-skeleton-loader__list-item-avatar {
  padding: 0;
}
::v-deep .preference-loader .v-skeleton-loader__avatar.v-skeleton-loader__bone {
  width: 24px;
  height: 24px;
}
</style>
