<template>
  <CLNotification
    heading="We've sent you an email"
    icon="mdi-email"
  >
    <p>
      If your username is valid, we've sent the instructions for how to get back into your account to your registered email address. Please open the link in
      the email to log into your ClariNet account.
    </p>
    <p>
      If you think you've made a mistake with your username, use the button below to try again
    </p>
    <p>
      <CLButton
        qe-id="forgot-password-try-again"
        secondary
        large
        @click="$emit('try-again')"
      >
        Try Password Reset Again
      </CLButton>
    </p>
  </CLNotification>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLButton,
  CLNotification,
} from "@clearlife-limited/ui-library";

export default defineComponent({
  name: "ClForgotPasswordEmailSent",
  components: {
    CLButton,
    CLNotification,
  },
});
</script>
