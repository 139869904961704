<template>
  <CLCard
    heading="I've forgotten my password"
    type="secondary"
    action-text="Send password reset email"
    action-event="send-reset-email"
    @send-reset-email="$emit('send-reset-email', username)"
  >
    <template #default>
      <p>No problem. Enter your ClearLife username and we'll send you a password reset email.</p>
      <CLTextbox
        v-model="username"
        label="Your username"
        qe-id="forgot-password-username"
        :rules="rules.username"
      />
    </template>
    <template #links>
      <CLTextLink qe-id="forgot-password-forgot-username" @click="$emit('forgot-username')">
        I've forgotten my username
      </CLTextLink>
      <br>
      <CLTextLink qe-id="forgot-password-return-login" @click="$emit('return')">
        Return to log in page
      </CLTextLink>
    </template>
  </ClCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLCard,
  CLTextbox,
  CLTextLink,
  required,
  validatorSetup
} from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLCard,
    CLTextbox,
    CLTextLink,
  },
  emits: {
    return: () => true,
    ["forgot-username"]: () => true,
    ["send-reset-email"]: (() => true) as (username: string) => boolean,
  },
  data() {
    return {
      username: "",
    };
  },
  computed: {
    rules() {
      return validatorSetup({
        username: { required: required("Username") },
      });
    },
  },
});
</script>

<style scoped lang="scss">
::v-deep .cl-textbox {
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>
