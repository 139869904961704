<template>
  <CLNotification
    heading="You do not have access to view this page."
    icon="mdi-alert"
  >
    <p>
      Please contact your client administrator.
    </p>
    <CLButton qe-id="no-access-log-out" primary @click="logout">
      Log out
    </CLButton>
  </CLNotification>
</template>

<script setup lang="ts">
import { CLNotification, CLButton } from "@clearlife-limited/ui-library";
import { router } from "../router";
function logout() {
  router.push("/Logout");
}
</script>
