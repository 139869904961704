<template>
  <CLNotification
    heading="Please contact your client administrator"
    icon="mdi-alert"
  >
    Your client administrator will be able to reset your MFA code by resetting your password.
  </CLNotification>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CLNotification } from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLNotification,
  },
});
</script>
