<template>
  <CLCard
    width="620px"
    height="660px"
    heading="Enter MFA Code"
    type="primary"
    action-text="Log in"
    action-event="submit"
    :loading="loading"
    @submit="onSubmit"
  >
    <template #default>
      <p>You need to enter the code shown on your Multi-Factor Authentication (MFA) app on your phone to continue.</p>
      <CLTextbox
        v-model="authenticatorCode"
        qe-id="enter-mfa-code"
        class="text-details-height-fix"
        autofocus
        autocomplete="false"
        label="Authenticator code"
        :error-messages="errorMessages"
        :rules="rules.authenticatorCode"
        maxlength="6"
        @input="errorMessages = []"
      />
    </template>
    <template #links>
      <CLTextLink qe-id="enter-mfa-code-do-not-have-access" @click="$emit('no-mfa')">
        I don't have access to MFA anymore
      </CLTextLink>
      <br>
      <CLTextLink qe-id="enter-mfa-code-return-to-login" @click="$emit('return')">
        Return to log in page
      </CLTextLink>
    </template>
  </CLCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLCard,
  CLTextbox,
  CLTextLink,
  required,
  validatorSetup
} from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLCard,
    CLTextbox,
    CLTextLink,
  },
  emits: {
    return: () => true,
    ["no-mfa"]: () => true,
    ["submit"]: (() => true) as (authenticatorCode: string, onError: (errorMessages: string[]) => void) => boolean,
  },
  data() {
    return {
      authenticatorCode: "",
      errorMessages: [] as string[],
      loading: false,
    };
  },
  computed: {
    rules() {
      return validatorSetup({
        authenticatorCode: { required: required("Authenticator Code") },
      });
    },
  },
  methods: {
    onError(errorMessages: string[]) {
      this.loading = false;
      this.errorMessages = errorMessages;
    },
    onSubmit() {
      if (this.loading) return;
      this.loading = true;
      this.$emit("submit", this.authenticatorCode, this.onError);
    },
  },
});
</script>

<style scoped lang="scss">
::v-deep .cl-textbox {
  margin-top: 15px;
  margin-bottom: 10px;
}
</style>
