<template>
  <cl-page>
    <cl-stack>
      <CLAlert
        v-if="showErrors"
        v-model="showErrors"
        qe-id="login-error"
        type="error"
      >
        <ul>
          <li
            v-for="em in errorMessage"
            :key="em"
          >
            {{ em }}
          </li>
        </ul>
      </CLAlert>
      <RedirectingToApplication
        v-if="redirectingToApplication"
      />
      <LoginForm
        v-else
        :login-failed="showErrors"
        :loading="loading"
        @login="login"
        @help="showHelp"
      />
    </cl-stack>
  </cl-page>
</template>

<script lang="ts">
import Vue from "vue";
import { mapStores } from "pinia";
import { CLAlert } from "@clearlife-limited/ui-library";
import LoginForm, { type LoginData } from "@clarinet/src/Components/Login/LoginForm.vue";
import RedirectingToApplication from "@clarinet/src/Components/Login/RedirectingToApplication.vue";
import { fetchAndUpdateFontSize } from "@clarinet/src/Utility/UIPreferences";
import { LoginApi } from "@clarinet/src/api";
import { useAuthStore } from "@clarinet/src/State/Stores/AuthStore";

export const localAppName = "Local";

export default Vue.extend({
  components: {
    CLAlert,
    LoginForm,
    RedirectingToApplication,
  },
  data() {
    return {
      isLocalLogin: false,
      errorMessage: <string[]>[],
      showErrors: false,
      loading: false,
      redirectingToApplication: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    this.isLocalLogin = (this.$route.params.application || localAppName) === localAppName;

    if (this.authStore.readAndResetLoginRestarted()) {
      this.errorMessage = ["Your login session has expired, please try again"];
      this.showErrors = true;
    }

    if (!this.isLocalLogin && !this.authStore.returnUrl) {
      const response = await LoginApi.applicationHome(this.$route.params.application);
      if (response.status === 200) {
        location.assign(response.data);
      }
    }
  },
  methods: {
    async login(loginData: LoginData) {
      if (this.loading) return;
      this.loading = true;
      this.errorMessage = [];
      this.showErrors = false;

      const returnUrl = this.authStore.returnUrl;
      if (!returnUrl) {
        console.error("Login: No return URL found in session storage");
        this.errorMessage = [
          "Something went wrong with your login request, please reload the page and try again",
        ];
        this.showErrors = true;
        this.loading = false;
        return;
      }

      const response = await LoginApi.login({
        userName: loginData.username,
        password: loginData.password,
        rememberLogin: loginData.rememberMe,
        returnUrl: returnUrl,
      });

      if (response.status === 200 && response.data.actionMfa === false) {
        await fetchAndUpdateFontSize();
      }

      if (response.data.returnToApplication) {
        // If the page takes longer than a second to redirect, we should show a spinner to let them know something is happening.
        setTimeout(() => this.redirectingToApplication = true, 1000);

        this.authStore.returnUrl = null;

        // If we're doing an OIDC response we need a full navigation, otherwise Vue Router can handle it for us
        if (returnUrl.startsWith("/connect")) {
          location.assign(returnUrl);
        } else {
          this.$router.push(returnUrl);
        }
      } else if (response.data.enrollMfa) {
        this.$router.push("/EnrolMfa");
      } else if (response.data.actionMfa) {
        this.$router.push(`/LoginMfa/${loginData.rememberMe}`);
      } else {
        this.errorMessage = response.data.faults || [];
        this.showErrors = true;
        this.loading = false;
      }
    },
    showHelp() {
      this.$router.push({ name: "loginHelp" });
    },
  },
});
</script>
