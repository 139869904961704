import Vue from "vue";
import { mapStores } from "pinia";
import { MfaApi } from "@clarinet/src/api";
import { useAuthStore } from "@clarinet/src/State/Stores/AuthStore";

export default Vue.extend({
  data() {
    return {
      mfa: {
        loaded: false,
        enabled: <boolean | undefined>false,
        helpUrl: <string | undefined>undefined,
        authenticatorUri: <string>"",
        sharedKey: <string>"",
      },
      userId: <string | null>null,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    await this.getMfaStatus();
  },
  methods: {
    async onVerifyMfa(
      authenticatorCode: string,
      onAuthenticatorCodeValidated: (valid: boolean, newRecoveryCodes?: string[]) => void
    ) {
      const validateStatus = (status: number) => status === 200 || status === 400;
      const response = this.userId
        ? await MfaApi.enableById(this.userId, { code: authenticatorCode }, { validateStatus })
        : await MfaApi.enableSelf({ code: authenticatorCode }, { validateStatus });

      switch (response.status) {
        case 200:
          if (response.data.newRecoveryCodes) {
            const recoveryResponse = this.userId
              ? await MfaApi.recoveryByUserId(this.userId)
              : await MfaApi.recoverySelf();

            onAuthenticatorCodeValidated(true, recoveryResponse.data.codes ?? undefined);
            return;
          }

          onAuthenticatorCodeValidated(true);
          return;
        case 400:
          onAuthenticatorCodeValidated(false);
          break;
      }
    },
    async getMfaStatus() {
      this.userId = this.authStore.userId;
      const individualStatus = this.userId
        ? await MfaApi.statusByUserId(this.userId)
        : await MfaApi.status();

      this.mfa.enabled = individualStatus.data.hasMfa;
      this.mfa.helpUrl = individualStatus.data.mfaHelpUrl || "";
      this.mfa.authenticatorUri = individualStatus.data.authenticatorUri || "";
      this.mfa.sharedKey = individualStatus.data.sharedKey || "";

      this.mfa.loaded = true;
    },
  },
});
