<template>
  <CLNotification
    heading="Your company requires MFA to be set up"
    icon="mdi-alert"
  >
    <p>
      Multi-Factor Authentication (MFA) is a requirement for logging into ClearLife. This has been requested by your client administrator. We'll talk you
      through how to do this on the next step.
    </p>
    <EnableMfa
      v-if="!loading"
      :authenticator-uri="authenticatorUri"
      :shared-key="sharedKey"
      @verify-code="verifyCode"
      @success="success"
    />
  </CLNotification>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EnableMfa from "@clarinet/src/Components/Mfa/EnableMfa.vue"
import { CLNotification } from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLNotification,
    EnableMfa,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    authenticatorUri: {
      type: String,
      required: true,
    },
    sharedKey: {
      type: String,
      required: true,
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    verifyCode(authenticatorCode: string, onAuthenticatorCodeValidated: Function){
      this.$emit("verify-code", authenticatorCode, onAuthenticatorCodeValidated);
    },
    success() {
      this.$emit("success");
    },
  },
});
</script>

<style lang="scss" scoped>
.cl-notification > .cl-notification-content .cl-enablemfa {
    margin-top: 4rem;

    & ::v-deep .cl-dialog {
        display: inline-block;
    }
}
</style>
