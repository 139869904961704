<template>
  <cl-page>
    <ForgotUsernameForm
      v-if="!emailSent"
      @send-email="sendEmail"
      @return="returnToLogin"
    />
    <ForgotUsernameEmailSent
      v-if="emailSent"
      :email="emailAddress"
    />
  </cl-page>
</template>

<script lang="ts">
import Vue from "vue";
import ForgotUsernameForm from "@clarinet/src/Components/Login/ForgotUsernameForm.vue";
import ForgotUsernameEmailSent from "@clarinet/src/Components/Login/ForgotUsernameEmailSent.vue";
import { LoginApi } from "@clarinet/src/api";

export default Vue.extend({
  components: {
    ForgotUsernameForm,
    ForgotUsernameEmailSent,
  },
  data() {
    return {
      emailSent: false,
      emailAddress: "",
    };
  },
  methods: {
    async sendEmail(emailAddress: string) {
      const response = await LoginApi.forgotUsername({ emailAddress: emailAddress });
      if (response.status === 200) {
        this.emailAddress = emailAddress;
        this.emailSent = true;
      }
    },
    returnToLogin() {
      this.$goBack(2);
    },
  },
});
</script>
