import type _Vue from "vue";
import type VueRouter from "vue-router";

// the point of this is to record history as we go through the app,
// and to provide a method to go back to the previous route that we
// know is safe (i.e. not external) to avoid security issues.

declare module "vue/types/vue" {
  interface Vue {
    $goBack: (n: number) => void
  }
}

export default {
  install(Vue: typeof _Vue) {
    Vue.prototype.$goBack = function(n: number) {
      const history = this.$router.currentRoute?.meta?.history;
      if (history && history.length >= n) {
        const targetRoute = history[history.length - n];
        if (targetRoute) {
          this.$router.push(targetRoute);
        }
      } else {
        console.warn("No previous history found, navigating to default route", history);
        this.$router.push("/"); // fallback, default route
      }
    };
  }
};

const recordHistory = (router: VueRouter) => {
  router.beforeEach((to, from, next) => {
    if (to.meta === undefined) {
      to.meta = {};
    }
    to.meta.history = [...from.meta?.history || []];
    to.meta.history.push(from.path);
    if (to.meta.history.length > 10) {
      to.meta.history = to.meta.history.slice(-10);
    }
    next();
  });
}

export { recordHistory };