<template>
  <cl-page :show-powered-by="false">
    <CreateAccountForm
      :initial-email="emailAddress"
      :error-message="errorMessage"
      :password-error-message="passwordErrorMessage"
      @create-account="createAccount"
    />
  </cl-page>
</template>

<script lang="ts">
import Vue from "vue";
import type { AxiosError } from "axios";
import { mapStores } from "pinia";
import { useAuthStore } from "@clarinet/src/State/Stores/AuthStore";
import CreateAccountForm, { type AccountSetupData } from "@clarinet/src/Components/Register/CreateAccountForm.vue";
import { InviteApi } from "@clarinet/src/api";
import axios from "axios";

export type ErrorResponse = {
  message: string;
  isPasswordError: boolean;
};
export default Vue.extend({
  components: {
    CreateAccountForm,
  },
  data() {
    return {
      acceptanceToken: "",
      emailAddress: "",
      errorMessage: "",
      passwordErrorMessage: "",
      userId: "",
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId as string || "";
      this.emailAddress = this.$route.query.e as string || "";
      this.acceptanceToken = this.$route.query.t as string || "";
    } else {
      this.$router.replace("/Login/ClariNet%20LS");
    }
  },
  methods: {
    async createAccount(setupData: AccountSetupData) {
      this.errorMessage = "";
      this.passwordErrorMessage = "";

      const payload = {
        userName: setupData.username,
        emailAddress: setupData.email,
        newPassword: setupData.password,
        newPasswordConfirmed: setupData.confirmPassword,
        acceptanceToken: this.acceptanceToken,
      };

      try {
        const response = await InviteApi.acceptInvitation(this.userId || "", payload);

        if (response.data.requireMfa) {
          if (this.userId) this.authStore.userId = this.userId;
          this.authStore.returnUrl = "/RegistrationComplete";
          await this.$router.replace("/EnrolMfa");
        } else {
          await this.$router.replace("/RegistrationComplete");
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<{ value: ErrorResponse }>;
          if (axiosError.response?.status === 400 && axiosError.response.data) {
            const error = axiosError.response.data.value;
            if (error.isPasswordError) {
              this.passwordErrorMessage = error.message;
            } else {
              this.errorMessage = error.message;
            }
          } else {
            this.errorMessage = "Sorry, something went wrong with your registration. Please try again. If you continue to have errors please contact support.";
          }
        }
      }
    },
  },
});
</script>
