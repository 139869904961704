<template>
  <cl-page>
    <CLCard
      heading="I need help logging in"
      action-text="Return to log in page"
      action-event="return"
      @return="returnToLogin"
    >
      <p>Please choose from the following help options:</p>
      <div class="cl-help-options">
        <CLIconButton
          icon="mdi-lock"
          qe-id="forgot-password"
          @click="forgotPassword"
        >
          I've forgotten my password
        </CLIconButton>
        <CLIconButton
          icon="mdi-account"
          qe-id="forgot-username"
          @click="forgotUsername"
        >
          I've forgotten my username
        </CLIconButton>
        <CLIconButton
          icon="mdi-email"
          qe-id="received-invite"
          @click="receivedInvite"
        >
          I've received an invite
        </CLIconButton>
      </div>
    </CLCard>
  </cl-page>
</template>

<script type="ts">
import Vue from "vue";
import {
  CLCard,
  CLIconButton,
} from "@clearlife-limited/ui-library";

export default Vue.extend({
  components: {
    CLCard,
    CLIconButton,
  },
  data() {
    return {};
  },
  methods: {
    returnToLogin() {
      this.$goBack(1);
    },
    forgotPassword() {
      this.$router.push("/ForgottenPassword");
    },
    forgotUsername() {
      this.$router.push("/ForgottenUsername");
    },
    receivedInvite() {
      this.$router.push("/ReceivedInvite");
    },
  },
});
</script>

<style scoped>
.cl-help-options {
  margin-bottom: 20px;
}

.cl-help-options > ::v-deep .cl-icon-button {
  margin-bottom: 10px;
}

.cl-help-options > ::v-deep .cl-icon-button:first-child {
  margin-top: 30px;
}
</style>
