import type { AxiosPromise, AxiosResponse } from "axios";
import { buildLoginRedirect } from "@clarinet/src/Utility/NavigationGuards";

export const handle401 = (error: { response: AxiosResponse }): AxiosPromise => {
  // If we hit an error that is not a 401 from one of our endpoints ignore the error
  if (error.response.status !== 401 || !requestWasToOurOrigin(error.response)) return Promise.reject(error);

  // Otherwise we need to respond by redirecting the user to the Login page with an appropriate return URL
  const redirectUrl = buildLoginRedirect(error.response);

  window.location.href = redirectUrl.toString();
  return Promise.reject(error);
};

const requestWasToOurOrigin = (response: AxiosResponse): boolean => {
  return new URL(window.location.href).origin === new URL(response.request.responseURL).origin;
};
