import { updateFontSizes } from "@clearlife-limited/ui-library";
import { UsersApi } from "@clarinet/src/api";

const STORAGE_KEY = "CL__UI--FontSize";

export async function fetchAndUpdateFontSize() {
  const response = await UsersApi.getUIFontSize();
  if (response.status === 200 && typeof response.data === "string") {
    const uiFontSize = response.data;
    localStorage.setItem(STORAGE_KEY, uiFontSize);
    updateFontSizes(uiFontSize)
  }
}

export async function getAndUpdateFontSize() {
  const uiFontSize = localStorage.getItem(STORAGE_KEY);
  if (typeof uiFontSize === "string") {
    updateFontSizes(uiFontSize);
  }
}

export function getLocalFontSize() {
  const uiFontSize = localStorage.getItem(STORAGE_KEY);
  return uiFontSize ?? "default";
}

export async function updateFontSize(fontSize: string) {
  const response = await UsersApi.setUIFontSize(fontSize);
  if (response.status === 200) {
    localStorage.setItem(STORAGE_KEY, fontSize);
    updateFontSizes(fontSize)
  }
}
