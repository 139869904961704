<template>
  <CLCard
    width="1050px"
    height="800px"
    heading="Set up your new password"
    type="primary-left-content"
    action-text="Save"
    action-size="small"
    action-event="save-new-password"
    :lazy-validation="true"
    @save-new-password="handleSubmit(password)"
  >
    <v-form ref="form">
      <p>Your password must contain at least 6 characters which should contain letters and numbers (uppercase or lowercase).</p>
      <CLPassword
        v-model="password"
        qe-id="setup-password"
        label="New password"
        :min="6"
        :rules="rules.password"
        @input="validateIfEdited"
        @change="validateIfEdited"
      />
      <CLPassword
        v-model="confirmPassword"
        qe-id="setup-confirm-password"
        label="Confirm new password"
        :min="6"
        :rules="rules.confirmPassword"
        @input="validateIfEdited"
        @change="validateIfEdited"
      />
    </v-form>
  </CLCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLCard,
  CLPassword,
  getPasswordRules,
  type Validator,
  validatorSetup,
} from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLCard,
    CLPassword,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    rules() {
      return validatorSetup({
        password: getPasswordRules("Password"),
        confirmPassword: getPasswordRules("Password", () => this.password, "Password"),
      });
    },
  },
  methods: {
    validateIfEdited() {
      if (this.password && this.confirmPassword) {
        (this.$refs.form as unknown as Validator)?.validate();
      }
    },
    handleSubmit(password: string) {
      const isValid = (this.$refs.form as unknown as Validator)?.validate();
      if (isValid) {
        this.$emit("save-new-password", password);
      }
    },
  },
});
</script>

<style scoped lang="scss">
::v-deep .cl-textbox {
  margin-top: 15px;
  margin-bottom: 10px;
}
</style>
