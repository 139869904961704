<template>
  <CLCard
    ref="card"
    type="primary-centred"
    heading="Redirecting to ClariNet"
    height="563px"
    :has-form="false"
    :has-action="false"
  >
    <template #default>
      <CLLoading visible indeterminate class="mt-8" />
    </template>
  </CLCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLCard,
  CLLoading,
} from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLCard,
    CLLoading,
  },
  inheritAttrs: false,
});
</script>
