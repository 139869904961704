<template>
  <cl-page>
    <EnterMFACode
      v-if="!recoveryMode && !noMfa"
      @submit="login"
      @no-mfa="initRecovery"
      @return="backToLogin"
    />
    <EnterMFARecoveryCode
      v-if="recoveryMode && !noMfa"
      @submit="login"
      @return="backToLogin"
      @no-recovery="noRecovery"
    />
    <NoMfaAccess v-if="noMfa" />
  </cl-page>
</template>

<script lang="ts">
import Vue from "vue";
import { mapStores } from "pinia";
import { useAuthStore } from "@clarinet/src/State/Stores/AuthStore";
import EnterMFACode from "@clarinet/src/Components/Mfa/EnterMFACode.vue"
import EnterMFARecoveryCode from "@clarinet/src/Components/Mfa/EnterMFARecoveryCode.vue";
import NoMfaAccess from "@clarinet/src/Components/Mfa/NoMfaAccess.vue";
import { MfaApi } from "@clarinet/src/api";
import { fetchAndUpdateFontSize } from "@clarinet/src/Utility/UIPreferences";

export default Vue.extend({
  components: {
    EnterMFACode,
    EnterMFARecoveryCode,
    NoMfaAccess,
  },
  data() {
    return {
      recoveryMode: false,
      noMfa: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore)
  },
  methods: {
    async login(code: string, onError?: (errorMessage: string[]) => void) {
      const returnUrl = this.authStore.returnUrl;
      if (!returnUrl) {
        console.error("LoginMFA: No return URL found in session storage");
        if (onError) {
          onError(["Something went wrong with your login request, please reload the page and try again"]);
        }
        return;
      }

      const payload = {
        twoFactorCode: code,
        returnUrl: returnUrl,
        rememberMe: this.$route.params.rememberMe === "true",
      };
      const response = this.recoveryMode
        ? await MfaApi.recovery(payload)
        : await MfaApi.login(payload);

      if (response.status === 200) {
        await fetchAndUpdateFontSize();
      }

      if (response.data.returnToApplication) {
        this.authStore.returnUrl = null;

        // If we're doing an OIDC response we need a full navigation, otherwise Vue Router can handle it for us
        if (returnUrl.startsWith("/connect")) {
          location.assign(returnUrl);
        } else {
          this.$router.push(returnUrl);
        }
      }

      if (response.data.restartLogin) {
        this.authStore.setLoginRestarted();
        this.$goBack(1);
        return;
      }

      if (onError && response.data.faults && response.data.faults.length) {
        onError(response.data.faults);
      }
    },
    initRecovery() {
      this.recoveryMode = true;
    },
    backToLogin() {
      this.$goBack(1);
    },
    noRecovery() {
      this.noMfa = true;
    },
  },
});
</script>
