<template>
  <CLCard
    heading="I've forgotten my username"
    type="secondary"
    action-text="Send username by email"
    action-event="send-email"
    @send-email="$emit('send-email', email)"
  >
    <template #default>
      <p>Enter the registered email for your ClariNet account and we'll send you a username reminder.</p>
      <CLTextbox
        v-model="email"
        qe-id="forgot-username-email"
        label="Your email address"
        :rules="rules.email"
      />
    </template>
    <template #links>
      <CLTextLink qe-id="forgot-username-return-login" @click="$emit('return')">
        Return to log in page
      </CLTextLink>
    </template>
  </CLCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLCard,
  CLTextbox,
  CLTextLink,
  email,
  required,
  validatorSetup,
} from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLCard,
    CLTextbox,
    CLTextLink,
  },
  emits: {
    return: () => true,
    ["send-email"]: (() => true) as (username: string) => boolean,
  },
  data() {
    return {
      email: "",
    };
  },
  computed: {
    rules() {
      return validatorSetup({
        email: {
          required: required("Email"),
          isEmail: email("Email"),
        },
      });
    },
  },
});
</script>

<style scoped lang="scss">
::v-deep .cl-textbox {
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>
