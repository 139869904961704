import type { ApiKey } from "@clarinet/api/auth";
import Vue from "vue";
import type { BoundValidators, Validator } from "@clearlife-limited/ui-library";
import {
  validatorSetup,
  CLDialog,
  CLButton,
  CLTextbox,
  CLCopyCodes,
  CLTable,
  required,
  createActionColDef,
  dateTimeFormatter
} from "@clearlife-limited/ui-library";
import type { GridOptions, ColDef } from "@ag-grid-community/core";
import { AgGridVue } from "@ag-grid-community/vue";
import DeleteButtonRenderer from "@clarinet/src/Components/Renderers/DeleteButtonRenderer.vue";
import type { DeleteButtonRendererParams } from "@clarinet/src/Components/Renderers/DeleteButtonRenderer";
import { ApiKeyApi } from "@clarinet/src/api";

const validateStatusIs2xxOrOther = (allowedStatus: number) => (responseStatus: number): boolean => {
  return (responseStatus >= 200 && responseStatus < 300) || responseStatus === allowedStatus;
};

export default Vue.extend({
  components: {
    AgGridVue,
    DeleteButtonRenderer,
    ClDialog: CLDialog,
    ClButton: CLButton,
    ClTextbox: CLTextbox,
    ClCopycodes: CLCopyCodes,
    CLTable,
  },
  data() {
    return {
      keys: <ApiKey[]>[],
      newKeyName: "",
      newKeyCreated: false,
      newKeyValue: "",
      newKeyCopied: false,
      haveApiKeysAccess: false,
      nameInUse: false,
      showDialog: false,
      gridOptions: <GridOptions<ApiKey>>{
        domLayout: "autoHeight",
        pagination: false,
        suppressPaginationPanel: true,
        getRowId: ({ data }) => data.name,
      },
      columnDefs: <ColDef[]>[
        createActionColDef<ColDef>({
          cellRenderer: "DeleteButtonRenderer",
          cellRendererParams: <DeleteButtonRendererParams>{
            itemName: "API Key",
          },
          sortable: false,
          resizable: false,
          flex: 2,
        }),
        {
          headerName: "Name",
          field: "name",
          flex: 4,
        },
        {
          headerName: "Last Accessed",
          field: "lastAccessed",
          flex: 3,
          valueFormatter: dateTimeFormatter,
        },
      ],
      valid: false,
    };
  },
  computed: {
    dialogHeading(): string {
      return this.newKeyCreated ? "Save this API key" : "Create API key";
    },
    dialogWidth(): string {
      return this.newKeyCreated ? "700" : "630";
    },
    rules(): BoundValidators {
      return validatorSetup({
        newKeyName: { required: required("API key name") },
      });
    },
    form(): Validator {
      return this.$refs.form as unknown as Validator;
    },
  },
  async mounted() {
    const response = await ApiKeyApi.getExisting({ validateStatus: validateStatusIs2xxOrOther(403) });

    if (response.status === 200) {
      this.keys = response.data;
      this.haveApiKeysAccess = true;
      this.$emit("loaded");
    }
  },
  methods: {
    async createApiKey() {
      this.form.validate();
      if (!this.valid) return;
      this.nameInUse = false;
      const newKeyName = this.newKeyName;
      const response = await ApiKeyApi.create(newKeyName, { validateStatus: validateStatusIs2xxOrOther(409) });

      if (response.status === 409) {
        this.nameInUse = true;
      } else if (response.status === 200) {
        this.newKeyValue = response.data;
        this.newKeyCreated = true;
        this.newKeyCopied = false;
        // Clone the keys to trigger a re-render
        this.keys = [...this.keys, { name: newKeyName, lastAccessed: null }];
      }
    },
    async deleteKey(name: string) {
      const response = await ApiKeyApi.delete(name);

      const index = this.keys.findIndex((key) => key.name === name);
      if (index === -1) return;
      if (response.status === 200) {
        this.keys.splice(index, 1);
        // Clone the keys to trigger a re-render
        this.keys = [...this.keys];
      }
    },
    closeDialog() {
      this.resetDialog();
      this.showDialog = false;
    },
    onDialogShownStateUpdate(shown: boolean) {
      !shown && this.resetDialog();
    },
    resetDialog() {
      this.newKeyCreated = false;
      this.newKeyCopied = false;
      this.nameInUse = false;
      this.newKeyName = "";
      this.newKeyValue = "";
      this.form.reset();
    },
  },
});
