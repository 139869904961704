<template>
  <CLCard
    width="1050px"
    height="800px"
    heading="Change security details"
    action-text="Continue"
    action-event="continue"
    action-size="small"
    type="primary-left-content"
    :loading="loading"
    @continue="$emit('continue', password)"
  >
    <CLKeyline />
    <h2>Please confirm your password</h2>
    <p>You'll then be able to change your password, amend your MFA details and create API keys.</p>
    <CLPassword
      v-model="password"
      autofocus
      :rules="rules.password"
      :error-messages="incorrectPasswordMessage"
      qe-id="confirm-password"
      @input="resetIncorrectPasswordMessage"
    />
  </CLCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLCard,
  CLKeyline,
  CLPassword,
  required,
  validatorSetup
} from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLPassword,
    CLKeyline,
    CLCard,
  },
  inheritAttrs: false,
  props: {
    incorrectPassword: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      incorrectPasswordMessage: [] as string[],
      password: "",
    };
  },
  computed: {
    rules() {
      return validatorSetup({
        password: {
          required: required("Password"),
        },
      });
    },
  },
  watch: {
    incorrectPassword(newVal: boolean) {
      this.incorrectPasswordMessage = newVal ? ["Invalid password or account is disabled."] : [];
    },
  },
  methods: {
    resetIncorrectPasswordMessage() {
      this.incorrectPasswordMessage = [];
      this.$emit("update:incorrectPassword", false);
    },
  },
});
</script>

<style scoped>
h2 {
    margin-top: 50px;
    margin-bottom: 30px;
}
</style>
