<template>
  <cl-page :show-powered-by="false">
    <MfaRequired
      :authenticator-uri="mfa.authenticatorUri"
      :shared-key="mfa.sharedKey"
      @verify-code="onVerifyMfa"
      @success="onSuccess"
    />
  </cl-page>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { useAuthStore } from "@clarinet/src/State/Stores/AuthStore";
import MfaRequired from "@clarinet/src/Components/Mfa/MfaRequired.vue";
import mfaComponent from "@clarinet/src/Mixins/MfaComponent";

export default mfaComponent.extend({
  components: {
    MfaRequired,
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    onSuccess() {
      const returnUrl = this.authStore.returnUrl;
      this.authStore.userId = null;
      this.authStore.returnUrl = null;
      if (!returnUrl) {
        console.error("EnrolMfa: No return URL found in session storage");
        return;
      }

      // If we're doing an OIDC response we need a full navigation, otherwise Vue Router can handle it for us
      if (returnUrl.startsWith("/connect")) {
        location.assign(returnUrl);
      } else {
        this.$router.push(returnUrl);
      }
    },
  },
});
</script>
