<template>
  <CLCard
    ref="card"
    :readonly="readonly"
    :use-spell-check="false"
    :loading="loading"
    heading="Log in"
    action-text="Log in"
    action-event="login"
    @login="handleLogin"
  >
    <template #default>
      <CLTextbox
        v-model="username"
        qe-id="login-username"
        label="Username"
        autocomplete="username"
        :rules="rules.username"
      />
      <CLPassword
        v-model="password"
        qe-id="login-password"
        autocomplete="current-password"
        :rules="rules.password"
      />
      <CLCheckbox
        v-model="rememberMe"
        qe-id="login-remember-me"
        label="Remember me"
      />
    </template>
    <template #links>
      <CLTextLink qe-id="login-need-help-logging-in" @click="$emit('help')">
        Need help logging in?
      </CLTextLink>
    </template>
  </CLCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  type Card,
  CLCard,
  CLCheckbox,
  CLPassword,
  CLTextbox,
  CLTextLink,
  validatorSetup,
  required,
} from "@clearlife-limited/ui-library";

export interface LoginData {
  username: string;
  password: string;
  rememberMe: boolean;
}

export default defineComponent({
  components: {
    CLTextbox,
    CLPassword,
    CLCheckbox,
    CLTextLink,
    CLCard,
  },
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    loginFailed: {
      type: Boolean,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  emits: {
    help: () => true,
    login: (() => true) as (loginData: LoginData) => boolean,
  },
  data() {
    return {
      valid: false,
      username: "",
      password: "",
      rememberMe: false,
    };
  },
  computed: {
    loginData(): LoginData {
      return {
        username: this.username,
        password: this.password,
        rememberMe: this.rememberMe,
      };
    },
    rules() {
      return validatorSetup({
        username: { required: required("Username") },
        password: { required: required("Password") },
      });
    },
    card(): Card {
      return this.$refs.card as unknown as Card;
    },
  },
  watch: {
    loginFailed(newValue: boolean){
      if(newValue){
        this.clearPassword();
        this.card.resetValidation();
      }
    },
  },
  methods: {
    handleLogin() {
      if (this.loading) return;
      this.$emit("login", this.loginData);
    },
    clearPassword(){
      this.password = "";
    },
  },
});
</script>

<style scoped>
.cl-textbox:first-child {
    margin-top: 50px !important;
}
.cl-textbox:nth-child(2) {
    margin-top: 30px !important;
}
</style>
