<template>
  <div class="mfa-admin-container mb-2">
    <h2>Multi-Factor Authentication (MFA)</h2>
    <template v-if="mfa.loaded">
      <p>
        MFA is currently: <b>{{ mfa.enabled ? "enabled" : "disabled" }}.</b>
        <template v-if="mfa.helpUrl">
          &nbsp;<a
                  :href="mfa.helpUrl"
                  target="_blank"
                >What is MFA?</a>
          <v-icon
            class="open-in-new"
            @click="openMfaHelp"
          >
            mdi-open-in-new
          </v-icon>
        </template>
      </p>
      <EnableMfa
        v-if="!mfa.enabled"
        :authenticator-uri="mfa.authenticatorUri"
        :shared-key="mfa.sharedKey"
        class="mfa-form-margin"
        @verify-code="onVerifyMfa"
        @success="getMfaStatus"
      />
      <template v-else>
        <div v-if="mfaLockReason">
          {{ mfaLockReason }}
        </div>
        <div>
          <CLButton
            secondary
            small
            qe-id="disable-mfa"
            :disabled="mfa.required"
            @click="mfa.required ? undefined : showDisableMfaDialog = true"
          >
            Disable MFA
          </CLButton>
        </div>
        <CLConfirmDialog
          :show.sync="showDisableMfaDialog"
          heading="Disable MFA"
          class="mfa-form-margin"
          width="600px"
          @ok="disableMfa"
        >
          Are you sure you want to disable MFA on your account?
        </CLConfirmDialog>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { CLButton, CLConfirmDialog } from "@clearlife-limited/ui-library";
import EnableMfa from "./EnableMfa.vue";
import mfaWithCompanyCheckComponent from "@clarinet/src/Mixins/MfaWithCompanyCheckComponent";
import { MfaApi } from "@clarinet/src/api";

export default mfaWithCompanyCheckComponent.extend({
  data() {
    return {
      showDisableMfaDialog: false,
    };
  },
  components: {
    CLButton,
    CLConfirmDialog,
    EnableMfa,
  },
  computed: {
    mfaLockReason(): string | null {
      return this.mfa.required
        ? "MFA is required by your company and cannot be disabled for your account."
        : null;
    },
  },
  methods: {
    async disableMfa() {
      await MfaApi.disable();
      await this.getMfaStatus();
    },
    openMfaHelp() {
      !!this.mfa.helpUrl && window.open(this.mfa.helpUrl);
    },
  },
});
</script>

<style scoped>
::v-deep .mfa-form-margin .v-form {
    margin-top: 0 !important;
}
</style>
