<template>
  <CLNotification
    heading="You can now log in"
    icon="mdi-check"
  >
    <p>
      You should now be able to log in using the password you have just created.
    </p>
    <CLButton
      qe-id="you-can-login-now-login"
      secondary
      large
      @click="$emit('login')"
    >
      Log in
    </CLButton>
  </CLNotification>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLButton,
  CLNotification
} from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLNotification,
    CLButton,
  },
});
</script>
