<template>
  <CLNotification
    heading="We've sent you an email"
    icon="mdi-email"
  >
    If your email address is valid, we've sent the confirmation of your username to <b>{{ email }}</b>
  </CLNotification>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CLNotification } from "@clearlife-limited/ui-library";

export default defineComponent({
  components: {
    CLNotification,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
});
</script>
