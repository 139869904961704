import Vue from "vue";
import { mapStores } from "pinia";
import { LoginApi } from "@clarinet/src/api";
import { useAuthStore } from "@clarinet/src/State/Stores/AuthStore";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  async mounted() {
    // If we're not told where the user wants to go after auth, assume it's to change their security details
    const returnUrl = (this.$route.query.ReturnUrl as string) || "/SecurityDetails";
    this.authStore.returnUrl = returnUrl;

    const response = await LoginApi.getLogin(returnUrl);
    if (response.status === 200) {
      this.$router.replace(`/Login/${response.data}`);
    }
  },
});
