<template>
  <cl-page :show-powered-by="false">
    <YouCanNowLogin @login="login" />
  </cl-page>
</template>

<script lang="ts">
import Vue from "vue";
import YouCanNowLogin from "@clarinet/src/Components/Login/YouCanNowLogin.vue";

export default Vue.extend({
  components: {
    YouCanNowLogin,
  },
  methods: {
    login() {
      this.$router.replace("/Login/ClariNet%20LS");
    },
  },
});
</script>
