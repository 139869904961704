import type { NavigationGuardNext, Route } from "vue-router";
import type { Dictionary } from "vue-router/types/router";
import type { AxiosResponse } from "axios";
import axios from "axios";
import { UserRolesApi } from "@clarinet/src/api";

export const ensureAuthenticated = async (to: Route, from: Route, next: NavigationGuardNext) => {
  // Not a traditional navigation guard but does the trick.
  // This takes advantage of the fact that a 401 from Axios will be handled by redirecting the user to login.
  // Might be nicer to replace this with a proper nav guard at some point.
  await axios
    .get("/api/Login/Check", {
      validateStatus: (status: number) => status === 200 || status === 401 || status === 403,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        next();
        return;
      }

      // On 401
      const redirect = buildLoginRedirect(response);

      const query: Dictionary<string> = {};
      redirect.searchParams.forEach((value, key) => (query[key] = value));

      next({
        path: redirect.pathname,
        query,
      });
    });
};

export const buildLoginRedirect = (response: AxiosResponse): URL => {
  // Take the existing Location header from the response if present as the source of truth about the login endpoint
  // If it doesn't exist build a new one
  const loginRedirectUrl = extractLocationHeaderAsURL(response) || new URL("/Login");

  // Assign the correct ReturnUrl
  loginRedirectUrl.searchParams.set("ReturnUrl", window.location.pathname);

  return loginRedirectUrl;
};

const extractLocationHeaderAsURL = (response: AxiosResponse): URL | undefined => {
  try {
    return response?.headers?.location ? new URL(response.headers.location) : undefined;
  } catch {
    return;
  }
};

export const ensurePermissions = (requiredRole: string) =>
  async (to: Route, from: Route, next: NavigationGuardNext) => {
    if (!requiredRole) {
      RedirectToNoAccessPage(next);
    }

    UserRolesApi.isSelfAssignedWithRole(requiredRole, { validateStatus: (status: number) => status === 200 || status === 401 || status === 403 })
      .then((response: AxiosResponse) => {
        if (response.status === 200 && response.data) {
          next();
          return;
        }

        // On 401
        RedirectToNoAccessPage(next);
      });
  };

function RedirectToNoAccessPage(next: NavigationGuardNext) {
  next({
    path: "/NoAccess",
  });
}
