import type { AxiosInstance } from "axios";
import type { Configuration } from "@clarinet/api/auth";
import axios from "axios";
import {
  ApiKeyApiFactory,
  ChangeDetailsApiFactory,
  InviteApiFactory,
  LoginApiFactory,
  MetaApiFactory,
  MfaApiFactory,
  OfficeApiFactory,
  OrganisationsApiFactory,
  RolesApiFactory,
  UserRolesApiFactory,
  UsersApiFactory,
} from "@clarinet/api/auth";

type RemoveSuffix<T> = {
  [K in keyof T as K extends `${infer Prefix}Method${"GET"|"PUT"|"POST"|"PATCH"|"DELETE"}${string}` ? Prefix : K]: T[K];
};
type RemoveSuffixKey<T> = keyof RemoveSuffix<T>;

/**
 * Our API is generated with metadata after the method name (e.g. acceptInvitationMethodGETControllerInvite).
 * We want to extract the method name.
 * @param operationId The swagger operation id
 */
function extractMethodName(operationId: string): string {
  const matches = operationId.match(/^(.+?)Method(GET|PUT|POST|PATCH|DELETE)(.+)$/);

  if (matches && matches.length >= 2) return matches[1];
  return operationId;
}

/**
 * Corrected API that removes the controller name from the end of the methods
 * (e.g. acceptInvitationMethodGETControllerInvite becomes acceptInvitation)
 * @param api OpenAPI generated API
 */
function mapApi<T extends object>(api: T): RemoveSuffix<T> {
  const result = {} as RemoveSuffix<T>; // have to cast to get rid of the return error
  for (const key in api) {
    if (Object.prototype.hasOwnProperty.call(api, key)) {
      const methodName = extractMethodName(key) as RemoveSuffixKey<T>;
      result[methodName] = api[key] as unknown as RemoveSuffix<T>[RemoveSuffixKey<T>];
    }
  }
  return result;
}

type FactoryParams = [undefined | Configuration, undefined | string, undefined | AxiosInstance];
const baseUrl = window.location.origin;
const factoryParams: FactoryParams = [undefined, baseUrl, axios];

// Corrected so that each method name doesn't have the controller name at the end
export const ApiKeyApi = mapApi(ApiKeyApiFactory(...factoryParams));
export const ChangeDetailsApi = mapApi(ChangeDetailsApiFactory(...factoryParams));
export const InviteApi = mapApi(InviteApiFactory(...factoryParams));
export const LoginApi = mapApi(LoginApiFactory(...factoryParams));
export const MetaApi = mapApi(MetaApiFactory(...factoryParams));
export const MfaApi = mapApi(MfaApiFactory(...factoryParams));
export const OfficeApi = mapApi(OfficeApiFactory(...factoryParams));
export const OrganisationsApi = mapApi(OrganisationsApiFactory(...factoryParams));
export const RolesApi = mapApi(RolesApiFactory(...factoryParams));
export const UserRolesApi = mapApi(UserRolesApiFactory(...factoryParams));
export const UsersApi = mapApi(UsersApiFactory(...factoryParams));
