import { defineComponent } from "vue";
import {
  CLSearchbox,
} from "@clearlife-limited/ui-library";
import { debounce } from "lodash";

export default defineComponent({
  components: {
    ClSearchbox: CLSearchbox
  },
  props: {
    value: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchText: ""
    }
  },
  watch: {
    searchText(newSearchText: string) {
      this.debounceSearch(newSearchText);
    }
  },
  methods: {
    debounceSearch: debounce(function (this: Vue, searchTerm: string) {
      this.$emit("input", searchTerm || "");
    }, 250),
  }
});