<template>
  <cl-page :show-powered-by="false">
    <ConfirmPassword
      v-if="!passwordConfirmed"
      :incorrect-password.sync="incorrectConfirmedPassword"
      :loading="confirmLoading"
      @continue="confirmPassword"
    />
    <CLCard
      v-else
      heading="Change Security Details"
      width="1110px"
      height="auto"
      type="primary-left-content"
      :has-action="false"
    >
      <CLKeyline />
      <div>
        <h2>Change your Password</h2>
        <p>Your password must contain at least 6 characters which should contain at least 1 uppercase, 1 lowercase and 1 number.</p>

        <v-form
          ref="passwordForm"
          v-model="valid"
          class="mt-0"
        >
          <Password
            ref="password"
            is-new
            show-confirm
          />
          <CLAlert
            v-if="hasErrors"
            v-model="hasErrors"
            type="error"
            qe-id="error-alert"
          >
            <span v-if="errorMessage.length === 1">{{ errorMessage[0] }}</span>
            <ul v-else>
              <li
                v-for="error in errorMessage"
                :key="error"
              >
                {{ error }}
              </li>
            </ul>
          </CLAlert>
          <CLAlert
            v-if="passwordChanged"
            v-model="passwordChanged"
            type="success"
            qe-id="password-changed-alert"
          >
            Your password has been changed
          </CLAlert>
          <div class="form-group">
            <CLButton
              qe-id="change-password"
              small
              primary
              @click.stop="changePassword"
            >
              Save
            </CLButton>
          </div>
        </v-form>
      </div>
      <CLKeyline />
      <UIPreferences />
      <CLKeyline />
      <MfaAdmin />
      <CLKeyline v-if="hasApiKeyAccess" />
      <ApiKeys @loaded="hasApiKeyAccess = true" />
      <CLKeyline />
      <EmailPreferences />
    </CLCard>
  </cl-page>
</template>

<script lang="ts">
import type { Validator } from "@clearlife-limited/ui-library";
import type { IPasswordComponent } from "@clarinet/src/Components/Password";
import {
  CLAlert,
  CLButton,
  CLCard,
  CLKeyline
} from "@clearlife-limited/ui-library";
import Vue from "vue";
import ApiKeys from "../Components/ApiKeys.vue";
import MfaAdmin from "../Components/Mfa/MfaAdmin.vue";
import Password from "../Components/Password.vue";
import EmailPreferences from "@clarinet/src/Components/EmailPreferences.vue";
import UIPreferences from "@clarinet/src/Components/UIPreferences.vue";
import ConfirmPassword from "@clarinet/src/Components/ConfirmPassword.vue";
import { LoginApi, ChangeDetailsApi } from "@clarinet/src/api";

export default Vue.extend({
  components: {
    EmailPreferences,
    Password,
    ApiKeys,
    MfaAdmin,
    CLAlert,
    CLButton,
    CLCard,
    ConfirmPassword,
    CLKeyline,
    UIPreferences,
  },
  data() {
    return {
      currentPassword: <string | null>null,
      hasApiKeyAccess: false,
      hasErrors: false,
      passwordChanged: false,
      passwordConfirmed: false,
      incorrectConfirmedPassword: false,
      confirmLoading: false,
      errorMessage: [] as string[],
      valid: false,
    };
  },
  computed: {
    passwordComponent(): IPasswordComponent {
      return this.$refs.password as unknown as IPasswordComponent;
    },
    passwordForm(): Validator {
      return this.$refs.passwordForm as unknown as Validator;
    },
  },
  methods: {
    async confirmPassword(password: string) {
      this.confirmLoading = true;
      this.passwordConfirmed = false;

      try {
        const response = await LoginApi.confirmIdentity({ password });
        this.passwordConfirmed = response.status === 200;
        this.currentPassword = password;
      } finally {
        this.incorrectConfirmedPassword = !this.passwordConfirmed;
        this.confirmLoading = false;
      }
    },
    async changePassword() {
      this.passwordForm.validate();
      if (!this.valid) return;

      this.hasErrors = false;
      this.errorMessage = [];
      this.passwordChanged = false;

      const payload = {
        ...this.passwordComponent.getData(),
        currentPassword: this.currentPassword,
      };
      const response = await ChangeDetailsApi.changePassword(payload);

      if (response.data.success) {
        this.passwordChanged = true;
        this.currentPassword = payload.newPassword;
      } else {
        this.hasErrors = true;
        this.errorMessage = response.data.faults ?? [];
      }

      this.passwordForm.reset();
    },
  },
});
</script>

<style scoped>
::v-deep h2 {
  margin-bottom: 1.5rem;
}

::v-deep .cl-card-content-container {
  margin-bottom: 3rem;
}

.open-in-new {
  font-size: 18px;
  vertical-align: baseline;
  cursor: pointer;
}
</style>
