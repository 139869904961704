<template>
  <CLCard
    width="1280px"
    height="1350px"
    heading="Create your account"
    type="primary-left-content"
    action-text="Create"
    action-event="create-account"
    action-size="small"
    @create-account="$emit('create-account', setupData)"
  >
    <CLKeyline />
    <h2 class="welcome-to-clearlife">
      Username creation
    </h2>
    <p>Your username should be a unique name as you'll use it to log in. For example: <b>firstname.lastname</b>.</p>
    <div>
      <CLTextbox
        v-model="username"
        qe-id="create-account-username"
        label="Username"
        class="no-mb"
        :rules="rules.username"
      />

      <CLAlert
        v-if="showPasswordError"
        v-model="showPasswordError"
        type="error"
        qe-id="create-account-password-error"
      >
        {{ passwordErrorMessage }}
      </CLAlert>
    </div>
    <h2>Password details</h2>
    <p>Your password must contain at least 6 characters which should contain letters and numbers (uppercase or lowercase).</p>
    <div>
      <v-form ref="form">
        <CLPassword
          v-model="password"
          qe-id="create-account-password"
          label="Password"
          :min="6"
          :rules="rules.password"
          @input="validateIfEdited"
          @change="validateIfEdited"
        />
        <CLPassword
          v-model="confirmPassword"
          qe-id="create-account-confirm-password"
          label="Confirm Password"
          :min="6"
          :rules="rules.confirmPassword"
          @input="validateIfEdited"
          @change="validateIfEdited"
        />
      </v-form>
    </div>
    <CLAlert
      v-if="showError"
      v-model="showError"
      error
      qe-id="create-account-error"
    >
      {{ errorMessage }}
    </CLAlert>
  </CLCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLAlert,
  CLCard,
  CLKeyline,
  CLPassword,
  CLTextbox,
  type Validator,
  getPasswordRules,
  regex,
  required,
  validatorSetup,
} from "@clearlife-limited/ui-library";

export interface AccountSetupData {
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
}

export default defineComponent({
  components: {
    CLAlert,
    CLCard,
    CLTextbox,
    CLPassword,
    CLKeyline,
  },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: "",
    },
    passwordErrorMessage: {
      type: String,
      required: false,
      default: null,
    },
    initialEmail: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      email: this.initialEmail,
      username: "",
      password: "",
      confirmPassword: "",
      showError: !!this.errorMessage,
      showPasswordError: !!this.passwordErrorMessage,
    };
  },
  computed: {
    setupData(): AccountSetupData {
      return {
        username: this.username,
        password: this.password,
        confirmPassword: this.confirmPassword,
        email: this.email,
      };
    },
    rules() {
      return validatorSetup({
        email: { required: required("Email") },
        username: {
          required: required("Username"),
          allowedUserName: regex(/^[A-Za-z0-9+._@-]+$/, "Your username can only contain letters, numbers and the following characters -._@+", false),
          // for userNameLengthLimit, we are limited by ClariNetLog.Log.MachineName column which is currently set to 32 characters.
          userNameLengthLimit: regex(/^.{1,32}$/, "Maximum allowed length of username is 32 characters.", false),
        },
        password: getPasswordRules("Password"),
        confirmPassword: getPasswordRules("Confirm Password", () => this.password, "Password"),
      });
    },
  },
  watch: {
    errorMessage(newVal: string) {
      this.showError = !!newVal;
    },
    passwordErrorMessage(newVal: string) {
      this.showPasswordError = !!newVal;
    },
    initialEmail(newVal: string) {
      this.email = newVal;
    },
  },
  methods: {
    validateIfEdited() {
      if (this.password && this.confirmPassword) {
        (this.$refs.form as unknown as Validator)?.validate();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.no-mb{
    margin-bottom: 0;
}

.cl-textbox {
    margin-top: 15px;
}

.cl-textbox:first-child {
    margin-bottom: 50px;
}

.cl-keyline {
    margin: 30px 0 30px 0;
}

h2.welcome-to-clearlife {
    margin: 40px 0 20px 0;
}

h2:not(.welcome-to-clearlife) {
    margin: 30px 0 30px 0;
}

p {
    margin-bottom: 35px;
}

::v-deep .cl-button {
    margin-top: 50px !important;
}
</style>
