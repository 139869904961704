<template>
  <CLNotification
    heading="Login Failed"
    icon="mdi-alert"
  >
    <p>
      {{ reasonMessage }}
    </p>
    <CLButton
      qe-id="login-rejected-retry-button"
      class="mt-3"
      secondary
      @click="backToLogin"
    >
      {{ buttonText }}
    </CLButton>
  </CLNotification>
</template>

<script lang="ts">
import { CLButton, CLNotification } from "@clearlife-limited/ui-library";

export default {
  name: "LoginRejected",
  components: {
    CLButton,
    CLNotification,
  },
  computed: {
    reason() {
      return this.$route.query?.reason?.toString().toLowerCase();
    },
    isAlreadyLoggedIn() {
      return this.reason === "already_logged_in";
    },
    isInactiveClient() {
      return this.reason === "inactive_client";
    },
    isBadResponseType() {
      return this.reason === "invalid_response_type";
    },
    reasonMessage() {
      if (this.isAlreadyLoggedIn) {
        return "This user is already logged in. Make sure you use the Logout item in the user menu. Please try again in one minute.";
      }
      if (this.isInactiveClient) {
        return "This user's client is no longer active. Please contact support."
      }
      if (this.isBadResponseType) {
        // This is as user-friendly a fudge as I can think of. The issue is that the old Web redirect URL is in session
        // storage and that isn't valid for WebCore's set up. Users will only see this if they log out of Web before the
        // release and leave the login page open to log in after the release. Loading the new script on login will fix
        // the session state and mean they won't see this again.
        return "An expired parameter was supplied during login. Please try again."
      }
      return "Reason not known. Please try again.";
    },
    buttonText() {
      if (this.isInactiveClient) {
        return "Back"
      }
      return "Retry";
    }
  },
  methods: {
    backToLogin() {
      const url = "/Login/ClariNet%20LS";
      if (this.isBadResponseType) {
        // We need the client to load the new script so this is a full navigation.
        location.replace(url);
        return;
      }
      this.$router.replace(url);
    }
  }
}
</script>
