import VueRouter from "vue-router";
import multiguard from "vue-router-multiguard";
import { ensureAuthenticated, ensurePermissions } from "@clarinet/src/Utility/NavigationGuards";
import ForgotPassword from "./Pages/ForgotPassword.vue";
import ForgotUsername from "./Pages/ForgotUsername.vue";
import ReceivedInvite from "./Pages/ReceivedInvite.vue";
import Login from "./Pages/Login.vue";
import LoginHelp from "./Pages/LoginHelp.vue";
import LoginStart from "./Pages/LoginStart.vue";
import LoginMfa from "./Pages/LoginMfa.vue";
import LoginRejected from "./Pages/LoginRejected.vue";
import SecurityDetails from "./Pages/SecurityDetails.vue";
import Users from "./Pages/Users.vue";
import AddUser from "./Pages/AddUser.vue";
import EditUser from "./Pages/EditUser.vue";
import InviteUser from "./Pages/InviteUser.vue";
import AcceptInvite from "./Pages/AcceptInvite.vue";
import RegistrationComplete from "./Pages/RegistrationComplete.vue";
import EnrolMfa from "./Pages/EnrolMfa.vue";
import NoAccess from "./Pages/NoAccess.vue";
import { recordHistory } from "./Plugins/routerHistoryPlugin";

const userAdminGuard = multiguard([ensureAuthenticated, ensurePermissions("SubscriberAdministration")]);
const sysAdminGuard = multiguard([ensureAuthenticated, ensurePermissions("SystemAdministration")]);

export const router = new VueRouter({
  routes: [
    { path: "/", redirect: "/Login" },
    { path: "/Login/:application", component: Login, meta: { title: "Log in" } },
    { path: "/Login", component: LoginStart, meta: { title: "Log in" } },
    { path: "/LoginMfa/:rememberMe", component: LoginMfa, meta: { title: "Log in" } },
    { path: "/EnrolMfa", component: EnrolMfa, meta: { title: "Enable MFA" } },
    { path: "/LoginHelp", component: LoginHelp, name: "loginHelp", meta: { title: "Log in" } },
    { path: "/LoginRejected", component: LoginRejected, meta: { title: "Log in" } },
    { path: "/SecurityDetails", component: SecurityDetails, meta: { title: "Security Details" }, beforeEnter: ensureAuthenticated },
    { path: "/ForgottenPassword", component: ForgotPassword, meta: { title: "Forgotten password" } },
    { path: "/ForgottenUsername", component: ForgotUsername, meta: { title: "Forgotten username" } },
    { path: "/ReceivedInvite", component: ReceivedInvite, meta: { title: "Create account" } },
    { path: "/RegistrationComplete", component: RegistrationComplete, meta: { title: "Create account" } },
    { path: "/Users/Invite", component: InviteUser, name: "userInvite", meta: { title: "User admin" }, beforeEnter: userAdminGuard },
    { path: "/Users/Reinvite/:userId", component: InviteUser, name: "userReinvite", meta: { title: "User admin" }, beforeEnter: userAdminGuard },
    { path: "/Users/Edit/:userName", component: EditUser, name: "editUser", meta: { title: "User admin" }, beforeEnter: userAdminGuard },
    { path: "/Users/Add", component: AddUser, meta: { title: "User admin" }, beforeEnter: sysAdminGuard },
    {
      path: "/Users/AddFirst/:organisationName/:publicId",
      component: AddUser,
      name: "addFirst",
      meta: { title: "User admin" },
      beforeEnter: sysAdminGuard
    },
    { path: "/Users/CompleteInvite", component: AcceptInvite, meta: { title: "Register" } },
    { path: "/Users", redirect: { name: "UsersList" } },
    //Moving URL to include extra segment to disambiguate fixed paths from those used to store list filter state
    { path: "/Users/List/:filter(admin|active|disabled|loggedIn)?/:search?", component: Users, meta: { title: "User admin" }, beforeEnter: userAdminGuard, name: "UsersList" },
    { path: "/NoAccess", component: NoAccess, meta: { title: "No Access" } },
  ],
  mode: "history",
});

router.afterEach((to) => {
  document.title = to.meta?.title ? `${to.meta.title} - ClearLife` : "ClearLife";
});

recordHistory(router);