import Vue from "vue";
import type { BoundValidators, Validator } from "@clearlife-limited/ui-library";
import { email } from "@clearlife-limited/ui-library";
import {
  required,
  validatorSetup,
  CLTextbox,
  CLButtonBar,
  CLButton,
  CLCopyCodes,
  CLKeyline,
  CLNotification,
} from "@clearlife-limited/ui-library";
import type { UserInvitationDto } from "@clarinet/api/auth";
import Roles from "@clarinet/src/Components/Roles.vue";
import type { RoleChanges } from "@clarinet/src/Components/Roles";
import { handleServerError } from "@clarinet/src/Components/ServerError";
import { InviteApi } from "@clarinet/src/api";

export default Vue.extend({
  components: {
    ClTextbox: CLTextbox,
    CLButtonBar,
    ClButton: CLButton,
    ClCopycodes: CLCopyCodes,
    ClKeyline: CLKeyline,
    Roles,
    CLNotification,
  },
  async beforeRouteEnter(to, from, next) {
    if (to.name === "userReinvite") {
      const userId = to.params.userId;
      const response = await handleServerError(InviteApi.resendInvite(userId));
      next((v) => (v as typeof this).processResendInvite(response.data));
    } else {
      next();
    }
  },
  data() {
    return {
      emailAddress: "",
      firstName: "",
      lastName: "",
      userInviteCode: <string | null>null,
      userCreated: false,
      valid: false,
      saveLoading: false,
      userRoles: <RoleChanges>{
        addRoles: [],
        removeRoles: [],
      },
    };
  },
  computed: {
    rules(): BoundValidators {
      return validatorSetup({
        emailAddress: {
          required: required("Email"),
          email: email("Email"),
        },
        firstName: { required: required("First name") },
        lastName: { required: required("Last name") },
      });
    },
    form(): Validator {
      return this.$refs.form as unknown as Validator;
    },
  },
  methods: {
    processResendInvite(invite: UserInvitationDto) {
      this.userCreated = true;
      this.userInviteCode = invite.inviteCode || null;
      this.emailAddress = invite.emailAddress;
      //These fields are validated during the postback even though at the point at which the email is sent (which is the next phase if we're entering here)
      //they are unused. We could either split the DTO, change the validation somehow or just set the fields to make everything happy
      this.firstName = "a";
      this.lastName = "a";
    },
    cancelInvite() {
      this.$goBack(1);
    },
    async createCode() {
      this.form.validate();
      if (!this.valid) return;
      this.saveLoading = true;
      const payload: UserInvitationDto = {
        emailAddress: this.emailAddress,
        firstName: this.firstName,
        lastName: this.lastName,
        roles: this.userRoles.addRoles,
      };
      try {
        const response = await handleServerError(InviteApi.createInvitableUser(payload));
        if (response.status === 200) {
          this.userCreated = true;
          this.userInviteCode = "" + response.data;
        }
      } finally {
        this.saveLoading = false;
      }
    },
    async sendInvite() {
      const payload:UserInvitationDto = {
        emailAddress: this.emailAddress,
        firstName: this.firstName,
        lastName: this.lastName,
        inviteCode: this.userInviteCode,
      };
      const response = await handleServerError(InviteApi.sendInviteEmail(payload));
      if (response.status === 200) {
        this.$goBack(1);
      }
    },
  },
});
