<template>
  <div class="cl-enablemfa">
    <CLDialog
      :heading="currentStage.dialogHeader"
      :show.sync="showDialog"
      width="665"
      @cancel="showDialog = false"
    >
      <template #activator>
        <CLButton
          qe-id="enable-mfa-button"
          secondary
          :small="buttonCaption.length < 11"
          :medium="buttonCaption.length > 10 && buttonCaption.length < 20"
          :large="buttonCaption.length >= 20"
          @click="openMfaDialog"
        >
          {{ buttonCaption }}
        </CLButton>
      </template>
      <template #actions>
        <CLButton
          v-if="!!currentStage.backButtonHandler"
          qe-id="enable-mfa-back-button"
          tertiary
          small
          @click="currentStage.backButtonHandler"
        >
          {{ currentStage.backButtonText }}
        </CLButton>
        <CLButton
          v-if="!!currentStage.forwardButtonHandler"
          qe-id="enable-mfa-next-button"
          secondary
          medium
          @click="currentStage.forwardButtonHandler"
        >
          {{ currentStage.forwardButtonText }}
        </CLButton>
      </template>
      <div v-show="stage === '1-download-app'">
        <p>
          To enable MFA you'll need an app. These can be installed on many different platforms such as mobile phone, tablet, Windows PC, Mac computers or web
          browser add-ons.
        </p>
        <p>Examples are:</p>
        <ul>
          <li>Google Authenticator (mobile devices only, search for "MFA" or "2FA" on your app store)</li>
          <li>
            <a
              href="https://authy.com/"
              target="_blank"
            >Authy</a>
            (multi-platform, on mobile, Windows and browser add-on)
          </li>
          <li>
            <a
              href="https://winauth.github.io/winauth/download.html"
              target="_blank"
            >WinAuth</a>
          </li>
        </ul>
      </div>
      <div v-show="stage === '2-scan-qr'">
        <p>This will set up a ClearLife profile on your device.</p>
        <QrCode
          :value="authenticatorUri"
          size="125"
        />
        <p>Alternatively, you can manually enter this key (spacing and casing do not matter).</p>
        <CLCopyCodes qe-id="copy-codes-qr" :codes="sharedKey" />
      </div>
      <div v-show="stage === '3-verify-code'">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="verifyMfa"
        >
          <p>
            Once you have scanned the QR code or input the key above, your MFA app will provide you with a unique code. Enter this code in the verification
            code box below.
          </p>
          <div class="row">
            <div class="col-md-6">
              <CLTextbox
                v-model="authenticatorCode"
                qe-id="enable-mfa-authenticator-code"
                autofocus
                :error-messages="errorMessage"
                :rules="rules.authenticatorCode"
                maxlength="6"
                label="Verification code"
                @input="errorMessage = []"
              />
            </div>
          </div>
        </v-form>
      </div>
      <div v-show="stage === '4-recovery-codes'">
        <p>
          If you lose your device and don't have the recovery codes you will lose access to your account.
        </p>
        <CLCopyCodes
          :columns="2"
          qe-id="copy-codes-recovery"
          :codes="recoveryCodes"
        />
      </div>
      <div v-show="stage === '5-complete'">
        <p>
          Multi-Factor Authentication is now enabled on your account.
        </p>
      </div>
    </CLDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CLButton,
  CLCopyCodes,
  CLDialog,
  CLTextbox,
  required,
  type Validator,
  validatorSetup,
} from "@clearlife-limited/ui-library";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import QrCodeVue from "qrcode.vue"; // added to vue-env.d.ts but still appears not to be recognized

interface Stages extends Record<string, StageData> {
  "1-download-app": StageData;
  "2-scan-qr": StageData;
  "3-verify-code": StageData;
  "4-recovery-codes": StageData;
  "5-complete": StageData;
}
interface StageData {
  dialogHeader: string;
  backButtonText?: string;
  backButtonHandler?: () => void;
  forwardButtonText: string;
  forwardButtonHandler: () => void;
}

export default defineComponent({
  components: {
    QrCode: QrCodeVue,
    CLButton,
    CLCopyCodes,
    CLDialog,
    CLTextbox,
  },
  props: {
    authenticatorUri: {
      type: String,
      required: true,
    },
    sharedKey: {
      type: String,
      required: true,
    },
    buttonCaption: {
      type: String,
      default: "Enable MFA",
    },
  },
  data() {
    return {
      authenticatorCode: "",
      errorMessage: [] as string[],
      success: false,
      recoveryCodes: [] as string[],
      showDialog: false,
      stage: "1-download-app" as keyof Stages,
      valid: false,
    };
  },
  computed: {
    currentStage(): StageData {
      return this.stages[this.stage];
    },
    stages(): Stages {
      return {
        "1-download-app": {
          dialogHeader: "Step 1 - Download an MFA app",
          backButtonText: "Cancel",
          backButtonHandler: this.closeDialog,
          forwardButtonText: "I have an App",
          forwardButtonHandler: () => (this.stage = "2-scan-qr"),
        },
        "2-scan-qr": {
          dialogHeader: "Step 2 - Scan this code with your MFA app",
          backButtonText: "Go back",
          backButtonHandler: () => (this.stage = "1-download-app"),
          forwardButtonText: "Continue",
          forwardButtonHandler: () => (this.stage = "3-verify-code"),
        },
        "3-verify-code": {
          dialogHeader: "Step 3 - Enter the code from MFA app",
          backButtonText: "Go back",
          backButtonHandler: () => (this.stage = "2-scan-qr"),
          forwardButtonText: "Finish Setup",
          forwardButtonHandler: this.verifyMfa,
        },
        "4-recovery-codes": {
          dialogHeader: "Step 4 - Put these codes in a safe place",
          forwardButtonText: "Done",
          forwardButtonHandler: this.closeDialog,
        },
        "5-complete": {
          dialogHeader: "MFA Enabled",
          forwardButtonText: "Done",
          forwardButtonHandler: this.closeDialog,
        },
      };
    },
    rules() {
      return validatorSetup({
        authenticatorCode: { required: required("Authenticator Code") },
      });
    },
  },
  watch: {
    stage(_, oldVal: keyof Stages) {
      if (oldVal === "3-verify-code") {
        ((this.$refs.form as unknown) as Validator).reset();
      }
    },
    showDialog() {
      this.onDialogVisibilityUpdate(this.showDialog);
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
    resetDialog() {
      this.authenticatorCode = "";
      this.errorMessage = [];
      this.stage = "1-download-app";
      ((this.$refs.form as unknown) as Validator).reset();
    },
    onDialogVisibilityUpdate(shown: boolean) {
      if (shown) return;

      this.resetDialog();
      if (this.success) this.$emit("success");
    },
    async verifyMfa() {
      ((this.$refs.form as unknown) as Validator).validate();
      if (!this.valid) return;

      this.$emit("verify-code", this.authenticatorCode, this.onAuthenticatorCodeValidated);
    },
    onAuthenticatorCodeValidated(valid: boolean, newRecoveryCodes?: string[]) {
      this.success = true;

      if (valid) {
        this.recoveryCodes = newRecoveryCodes || [];
        this.stage = newRecoveryCodes ? "4-recovery-codes" : "5-complete";
      } else {
        this.errorMessage = ["Verification code is invalid."];
      }
    },
    openMfaDialog() {
      this.$emit("init-mfa");
      this.showDialog=true;
    },
  },
});
</script>
