<template>
  <cl-page>
    <CLAlert
      v-if="hasError"
      v-model="hasError"
      qe-id="forgot-password-error"
      type="error"
      floating-alert
    >
      {{ errorMessage }}
    </CLAlert>
    <ForgotPasswordForm
      v-if="!resetToken && !passwordReset && !cannotReset"
      @send-reset-email="requestResetEmail"
      @forgot-username="forgotUsername"
      @return="returnToLogin"
    />
    <ForgotPasswordEmailSent
      v-if="!resetToken && passwordReset && !cannotReset"
      @try-again="returnToForm"
    />
    <ForgotPasswordUnableToReset v-if="!resetToken && !passwordReset && cannotReset" />
    <SetupNewPassword
      v-if="resetToken && !passwordReset"
      @save-new-password="completePasswordReset"
    />
    <YouCanNowLogin
      v-if="resetToken && passwordReset"
      @login="returnToLogin"
    />
  </cl-page>
</template>

<script lang="ts">
import Vue from "vue";
import { CLAlert } from "@clearlife-limited/ui-library";
import { LoginApi } from "@clarinet/src/api";
import axios from "axios";
import ForgotPasswordEmailSent from "@clarinet/src/Components/Login/ForgotPasswordEmailSent.vue";
import ForgotPasswordForm from "@clarinet/src/Components/Login//ForgotPasswordForm.vue";
import ForgotPasswordUnableToReset from "@clarinet/src/Components/Login//ForgotPasswordUnableToReset.vue";
import SetupNewPassword from "@clarinet/src/Components/Login//SetupNewPassword.vue";
import YouCanNowLogin from "@clarinet/src/Components/Login//YouCanNowLogin.vue";

export default Vue.extend({
  components: {
    CLAlert,
    ForgotPasswordEmailSent,
    ForgotPasswordForm,
    ForgotPasswordUnableToReset,
    SetupNewPassword,
    YouCanNowLogin,
  },
  data() {
    return {
      resetToken: <string | null>null,
      passwordReset: false,
      errorMessage: "",
      cannotReset: false,
    };
  },
  computed: {
    hasError: {
      get(): boolean { return !!this.errorMessage; },
      set(value: boolean) { if (!value) this.errorMessage = ""; }
    }
  },
  mounted() {
    if (this.$route.query.resetToken) {
      this.resetToken = this.$route.query.resetToken as string;
    }
  },
  methods: {
    async requestResetEmail(userName: string) {
      this.errorMessage = "";
      const response = await LoginApi.forgotPassword({ userName });
      if (response.status === 200) {
        if (response.data === "Reset not available") {
          this.cannotReset = true;
        } else {
          this.passwordReset = true;
        }
      } else {
        this.errorMessage = response.data;
      }
    },
    async completePasswordReset(password: string) {
      this.errorMessage = "";
      try {
        await LoginApi.forgotPassword({
          userName: this.$route.query.userName as string,
          token: this.resetToken,
          newPassword: password,
        });
        this.passwordReset = true;

      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data) {
          this.errorMessage = error.response.data;
          return;
        }

        this.errorMessage = "An error occurred while resetting your password. Please contact support if this problem persists.";
      }
    },
    forgotUsername() {
      this.$router.replace("/ForgottenUsername");
    },
    returnToLogin() {
      this.$router.replace("/Login/ClariNet%20LS");
    },
    returnToForm() {
      this.passwordReset = false;
      this.cannotReset = false;
    },
  }
});
</script>
